import { axiosPrivate } from "../api/axios";
import { useEffect } from "react";
import useAuth from "./useAuth";

const useAxiosPrivate = () => {
    const { auth ,setAuth } = useAuth();

    useEffect(() => {
        const requestIntercept = axiosPrivate.interceptors.request.use(
            config => {
                if (!config.headers['Authorization']) {
                    config.headers['Authorization'] = `Bearer ${auth?.token}`;
                }
                return config;
            }, (error) => Promise.reject(error)
        );

      /*  const responseIntercept = axiosPrivate.interceptors.response.use(
            response => response,
            async (error) => {
                const prevRequest = error?.config;
                if (error?.response?.status === 401 && !prevRequest?.sent) {
                    setAuth({
                        userId:auth.userId,
                        isLogin: false,
                        isAdmin: false,
                        isMerchant: false
                    })
                }
                return Promise.reject(error);
            }
        );
*/
        return () => {
            axiosPrivate.interceptors.request.eject(requestIntercept);
            /*axiosPrivate.interceptors.response.eject(responseIntercept);*/
        }
    }, [auth])

    return axiosPrivate;
}

export default useAxiosPrivate;