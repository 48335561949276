import {useQuery} from "@tanstack/react-query";
import useAxiosPrivate from "../../Hooks/useAxiosPrivate";
import useAuth from "../../Hooks/useAuth";

export const useGetRules = () => {

    const axiosPrivate =  useAxiosPrivate()

    return useQuery(
        ['rules'],
        async () => {
            const {data} = await axiosPrivate.get(`/api/v1/rules`)
            return data?.data;
        },
        {
            retry: 1,
            staleTime: 5000,
        });
}