import {createContext, useEffect, useState} from "react";

const GlobalContext = createContext({});

export const GlobalProvider = ({children}) => {

    const [global, setGlobal] = useState({
        accessDenied: false,
        otpLock: null,
        hideUserBalance: (localStorage.getItem("hideUserBalance") === null) || (localStorage.getItem("hideUserBalance") === "true"),
        hasError : false,
        isLoading : true,
        currencies: {},
        activeActionSheet: {
            menu: false,
            select: false,
        },
        selectType: null,
        selected_deposit_coin: null,
        selected_deposit_network: null,
        selected_exchange_base: null,
        selected_exchange_quote: null,
        selected_withdrawal_coin: null,
        selected_withdrawal_network: null,
        selected_withdrawal_iban: null,
        selected_tx_h_coin: null,
        selected_tx_h_category: null,
        selected_tx_h_size: null,
        reserveOrderRes: null,
    });

    useEffect(() => {
        if (global.otpLock) {
            localStorage.setItem("otpLock", global?.otpLock);
        }
        localStorage.setItem("hideUserBalance", global?.hideUserBalance);
    }, [global]);

    return (
        <GlobalContext.Provider value={{global, setGlobal}}>
            {children}
        </GlobalContext.Provider>
    )
}

export default GlobalContext;