import React, {useState} from 'react';
import classes from './Accounts.module.css';
import {useTranslation} from "react-i18next";
import AccountsList from "./Module/AccountsList/AccountsList";
import AddAccount from "./Module/AddAccount/AddAccount";
import NavBar from "../../../../components/NavBar/NavBar";

const Accounts = () => {

    const {t} = useTranslation();

    const [step, setStep] = useState(1)

    const tab = [
        {id: 1, title: t("LinkedBankAccounts.accounts"), action:  () => setStep(1), disabled: null},
        {id: 2, title: t("LinkedBankAccounts.newAccount"), action:() => setStep(2), disabled: null},
    ];

    const content = () => {

        if (step === 1) {
            return <AccountsList/>
        }
        if (step === 2) {
            return <AddAccount/>
        }
    }


    return (
        <div className={`${classes.container} column height-100 pt-3 pb-5 width-100 column jc-start ai-center`}>
            <NavBar
                tab={tab}
                navClass={`width-86`}
            />
            {content()}

        </div>
    );
};

export default Accounts;
