import React from 'react';
import classes from './NumberInput.module.css'
import Icons from "../Icon/Icons";
import * as RoutesName from "../../Routes/routes";
import useAuth from "../../Hooks/useAuth";
import NumberFormat from "react-number-format";
import useGlobal from "../../Hooks/useGlobal";

const NumberInput = ({maxDecimal, lead , after ,hint , alert , customClass, inputClass, onchange, label, subLabel, innerRef, textarea, value, icon, alerts, select, selectType, ...other }) => {

    const {global, setGlobal} = useGlobal();

    const selectClickHandler = () => {
        setGlobal({
            ...global,
            activeActionSheet: {
                menu: false,
                select: true,
            },
            selectType,
        })
    }

    const selectInput = (event) => {
        if (event.target.value === "0") {
            event.target.select();
        }
    }

    return (
        <div className={`${inputClass}`}>
            <div className={`${classes.inputGroup}`}
                 onClick={()=> select ? selectClickHandler() : "" }
            >

                {<NumberFormat
                    thousandSeparator={true}
                    allowNegative={false}
                    decimalScale={maxDecimal}
                    onChange={onchange}
                    value={value}
                    {...other}
                    onFocus={selectInput}
                    onClick={selectInput}
                />}

                {select && <Icons
                    iconName="icon-down-open flex fs-01"
                    iconClass={`cursor-pointer ${classes.select} position-absolute`}
                    /*onClick={() => selectClickHandler()}*/
                />}

                { icon && <span className={`${classes.icon}`}>{icon}</span>}

                <label className={value && classes.hasValue}>{label}</label>

                {/*{alerts?.length !== 0 && <Icons iconName="icon-info-circled flex fs-05 text-red" iconClass={`${classes.thisIcon}`}/>}*/}
            </div>
            {subLabel && <span className={`column fs-0-9 mt-05`}>
               {subLabel}
           </span>}
            { alert && <div className={`column fs-0-8 text-red mt-05`}>
                <span className={`pr-05 `}>{alert}</span>
            </div> }
        </div>


    );
};

export default NumberInput;
