import React, {useState} from 'react';
import classes from './DepositAddress.module.css'
import QRCode from "react-qr-code";
import {Trans, useTranslation} from "react-i18next";
import Icons from "../../../../../../components/Icon/Icons";
import {useGetDashboard, useGetDepositAddress} from "../../../../../../query";
import Icon from "../../../../../../components/Icon/Icons";
import toast from "react-hot-toast";
import Countdown from "react-countdown";
import moment from "moment-jalaali";
import {BN} from "../../../../../../utils/utils";

const DepositAddress = ({asset, network}) => {

    const {t} = useTranslation();

    const [expired, setExpired] = useState(false)


    const {data, isLoading, error, refetch} = useGetDepositAddress(asset, network)



    const copyToClipboard = (e, value) => {
        e.stopPropagation();
        e.preventDefault();
        navigator.clipboard.writeText(value)
        toast.success(t("copy"));
    }

    const content = () => {

        if (isLoading)  {
            return <>
                <div className={`${classes.rectangle} width-65 rounded-8`}/>
                <div className={`${classes.rectangle2} width-85 rounded-8 mt-3`}/>

            </>
        }
        if (error) {
            return <span className={`my-10`}>{t("error")}</span>
        }

        return <>

            <QRCode
                value={data[0]?.address}
                bgColor="var(--cardBody)"
                fgColor="var(--textColor)"
                level='L'
                size={130}
            />

            <span className={`mt-2 mb-1 font-weight-bold flex jc-center ai-center width-100 text-center ${classes?.address}`}>{data[0]?.address}</span>

            <div className={`width-100 flex jc-center ai-center mb-2`}>
                <Icon
                    iconName={`icon-copy fs-02 flex cursor-pointer`}
                    iconClass={``}
                    onClick={(e) => copyToClipboard(e, data[0]?.address)}
                />
            </div>


            <div className={`width-100 rounded-8 row jc-between ai-center ${classes.amountBox} px-5 py-1 my-1 position-relative `}>
                <span className={`width-40`}>{t("assignedDate")}</span>
                <div className={`width-60 row jc-end ai-center`}>
                    <span className={`fs-02 font-weight-bold`}>{moment(data[0]?.assignedDate).format("jYY/jMM/jDD - HH:mm:ss")}</span>
                </div>
            </div>

            <div className={`width-100 rounded-8 row jc-between ai-center ${classes.amountBox} px-5 py-1 my-1 position-relative `}>
                <span className={`width-40`}>{t("expTime")}</span>
                <div className={`width-60 row jc-end ai-center`}>
                    <span className={`fs-02 font-weight-bold`}>{moment(data[0]?.expTime).format("jYY/jMM/jDD - HH:mm:ss")}</span>
                </div>
            </div>

            <div className={`width-100 rounded-8 row jc-between ai-center ${classes.amountBox} px-5 py-1 my-1 position-relative `}>
                <span className={`width-40`}>{t("remainingTime")}</span>
                <div className={`width-60 row jc-end ai-center`}>
                    <span className={`fs-02 font-weight-bold`}><Countdown
                        date={parseInt(moment(data[0]?.expTime).unix()) * 1000 + 60 * 1000  }
                        renderer={props => <div className={ `${props.minutes === 0 && "text-red"} direction-ltr `}>{props.hours} : {props.minutes} : {props.seconds}</div>}
                        onComplete={() => refetch()}
                    /></span>
                </div>
            </div>



            <span className={`my-1 text-center`}>
                <Trans
                    i18nKey="Deposit.addressHint"
                    values={{
                        expDate: moment(data[0]?.expTime).format("jYY/jMM/jDD"),
                        expTime: moment(data[0]?.expTime).format("HH:mm:ss")
                    }}
                >
                    {/*<span className={`font-weight-bold fs-02 width-25 flex jc-center ai-center`}><Countdown
                        date={parseInt(moment(data[0]?.expTime).unix()) * 1000 + 60 * 1000  }
                        renderer={props => <div className={ `${props.minutes === 0 && "text-red"} direction-ltr `}>{props.hours} : {props.minutes} : {props.seconds}</div>}
                        onComplete={() => console.log("finished")}
                    /></span>*/}

                </Trans>

            </span>

            <span className={`font-weight-bold fs-02 width-25 flex jc-center ai-center`}></span>



        </>

    }


    return (
        <div className={`width-86 column jc-center ai-center m-auto`}>
            {content()}

        </div>
    );
};

export default DepositAddress;
