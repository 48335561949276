import React, {useEffect, useState} from 'react';
import classes from './Deposit.module.css';
import {Trans, useTranslation} from "react-i18next";
import TextInput from "../../../../components/TextInput/TextInput";
import DepositAddress from "./Module/DepositAddress/DepositAddress";
import ScrollBar from "../../../../components/ScrollBar";
import useGlobal from "../../../../Hooks/useGlobal";

const Deposit = () => {

    const {t} = useTranslation();

    const {global, setGlobal} = useGlobal();

    const [input, setInput] = useState({
        asset: {value: "", error: []},
        network: {value: "", error: []},
    });

    useEffect(()=>{
        /*if (auth?.imageIpfsLink) setItemData({...itemData,imageLink: { value: auth?.imageIpfsLink, error: []}})
        if (auth?.animationIpfsLink) setItemData({...itemData,mediaLink: { value: auth?.animationIpfsLink, error: []}})*/

        let newInputData = {...input}
        /*if (auth?.selected_deposit_coin) newInputData.asset = { value: auth?.selected_deposit_coin, error: []}*/


        if (global?.selected_deposit_coin) {
            newInputData = {...newInputData,
                asset : { value: global?.selected_deposit_coin, error: []},
                network: {value: "", error: []}
            }
            setGlobal({...global,
                selected_deposit_network: null
            })
        }

        /*if (auth?.selected_deposit_coin === input?.asset?.value) {
            newInputData = {...newInputData,
                asset : { value: auth?.selected_deposit_coin, error: []},
                network: {value: "", error: []}
            }
        }
*/
        if (global?.selected_deposit_network && (global?.selected_deposit_coin === input?.asset?.value)) {
            newInputData = {...newInputData,
                network: {value: global?.selected_deposit_network, error: []}
            }
        }

        if (!global?.selected_deposit_network) {
            newInputData = {...newInputData,
                network: {value: "", error: []}
            }
        }

        /*if (auth?.selected_deposit_network) newInputData.network = { value: auth?.selected_deposit_network, error: []}
        else newInputData.network = { value: "", error: []}*/

        /*if (auth?.asset_3DModelIpfsLink) newItemData.mediaLink = { value: auth?.asset_3DModelIpfsLink, error: []}*/
        setInput(newInputData)

    },[global?.selectType])


    /*useEffect(()=>{
        if( input?.network?.value !== "") {
            setInput({...input,
                network: {value: "", error: []}
            })
        }

    }, [input?.asset])*/

    const inputHandler = (e) => {
        let errorMessage = []
        let inputVal = e.target.value
        if (typeof e.target.dataset.min !== undefined && e.target.value.length < e.target.dataset.min) {
            errorMessage.push(<Trans
                i18nKey="Login.minInput"
                values={{
                    name: t(e.target.dataset.name),
                    min: e.target.dataset.min
                }}
            />)
        }
        let prevState = {
            ...input,
            [e.target.dataset.name]: {...input[e.target.dataset.name], value: inputVal, error: errorMessage}
        }
        setInput(prevState)
    }



    return (
        <div className={`${classes.container} width-86 height-100 rounded-8 m-auto column jc-center ai-center pb-5`}>
            <ScrollBar>
                <div className={`column jc-center ai-center width-100 mt-4 mb-2`}>
                    <TextInput
                        value={input.asset.value}
                        // value={t('currency.'+ input.asset.value)}
                        type="text"
                        label={t('asset')}
                        //placeholder={t('Login.mobilePh')}
                        data-name="asset"
                        data-type="input"
                        data-min={2}
                        //maxLength="10"
                        onchange={(e) => inputHandler(e)}
                        alerts={input.asset.error}
                        inputClass={`width-85 my-2`}
                        select={true}
                        readOnly={true}
                        selectType="deposit_coin"
                    />
                    <TextInput
                        value={input.network.value}
                        // value={t('currency.'+ input.asset.value)}
                        type="text"
                        label={t('network')}
                        //placeholder={t('Login.mobilePh')}
                        data-name="network"
                        data-type="input"
                        data-min={2}
                        //maxLength="10"
                        onchange={(e) => inputHandler(e)}
                        alerts={input.network.error}
                        inputClass={`width-85 my-2`}
                        select={true}
                        readOnly={true}
                        selectType="deposit_network"
                    />
                </div>
                {(input?.asset?.value && input?.network?.value) && <DepositAddress asset={input?.asset?.value} network={input?.network?.value}/>}
            </ScrollBar>
        </div>
    );
};

export default Deposit;
