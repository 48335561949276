import React, {useState} from 'react';
import classes from './WithdrawalAddressByNetwork.module.css';
import {Trans, useTranslation} from "react-i18next";
import {useGetCurrencies, useGetTransactions, useGetUserWallet} from "../../../../../../query";
import moment from "moment-jalaali";
import {BN, parsePriceString} from "../../../../../../utils/utils";
import TextInput from "../../../../../../components/TextInput/TextInput";
import NumberInput from "../../../../../../components/NumberInput/NumberInput";
import {reserveOrder, sendWithdrawReq} from "../../../../../../api/exchange";
import toast from "react-hot-toast";
import useAuth from "../../../../../../Hooks/useAuth";
import Button from "../../../../../../components/Button/Button";
import Loading from "../../../../../../components/Loading/Loading";
import Icon from "../../../../../../components/Icon/Icons";
import useGlobal from "../../../../../../Hooks/useGlobal";
import i18next from "i18next";

const WithdrawalAddressByNetwork = () => {

    const {t} = useTranslation();

    const {auth} = useAuth();
    const {global} = useGlobal();

    const asset = global?.selected_withdrawal_coin
    const network = global?.selected_withdrawal_network

    const {data, isLoading, error} = useGetCurrencies(asset)
    const {data: userWalletData, isLoading: userWalletIsLoading, error: userWalletError, refetch:refetchUserWallet} = useGetUserWallet()

    /*const {refetch:refetchTransactions} = useGetTransactions()*/

    const [loading, setLoading] = useState(false)

    const [isInputVisible, setIsInputVisible] = useState({
        password: false,
    });


    /*console.log("data", data[asset])
    console.log("userWalletData", userWalletData[asset])*/

    const precision = !isLoading && data?.precision
    const freeWallet = !userWalletIsLoading && userWalletData[asset]?.balance || 0
    const minWithdraw = !isLoading && data?.withdrawMin
    const withdrawFee = !isLoading && data?.withdrawFee
    /*const withdrawEnabled = !isLoading && data?.currencyImpData?.implementations[0]?.withdrawEnabled*/
    const withdrawEnabled = true




    /*console.log("freeWallet", freeWallet)
    console.log("precision", precision)
    console.log("minWithdraw", minWithdraw)
    console.log("withdrawFee", withdrawFee)*/

    const [amount, setAmount] = useState({value: new BN(0), error: []});

    const [input, setInput] = useState({
        /*amount: {value: "", error: []},*/
        address: {value: "", error: []},
        txPassword: {value: "", error: []},
    });

    const inputHandler = (e) => {
        let errorMessage = []
        let inputVal = e.target.value
        if (typeof e.target.dataset.min !== undefined && e.target.value.length < e.target.dataset.min) {
            errorMessage.push(<Trans
                i18nKey="Login.minInput"
                values={{
                    name: t(e.target.dataset.name),
                    min: e.target.dataset.min
                }}
            />)
        }
        let prevState = {
            ...input,
            [e.target.dataset.name]: {...input[e.target.dataset.name], value: inputVal, error: errorMessage}
        }
        setInput(prevState)
    }

    const isFormValid = () => {
        let inputs = {...input}

        const hasError = Object.values(input).find(input => input.error.length > 0)
        if (hasError) return false
        let isEmpty = false

        for (const key in inputs) {
            if (inputs[key].value.length === 0) {
                isEmpty = true
                inputs = {
                    ...inputs,
                    [key]: {
                        ...inputs[key],
                        error: [<Trans
                            i18nKey="Login.emptyInput"
                            values={{
                                name: t(key),
                            }}
                        />]
                    }
                }
            }
        }

        setInput(inputs);
        return !isEmpty;
    }


    const submit = async () => {

        if (!isFormValid()) return false;

        setLoading(true)

        const sendWithdrawReqParam = {
            "amount": amount?.value,
            "fee": withdrawFee,
            "destSymbol": asset.toUpperCase(),
            "destAddress": input.address.value,
            "destNetwork": network,
            "password": input.txPassword.value,
        }

        sendWithdrawReq(sendWithdrawReqParam, auth?.token)
            .then(async (res) => {


                toast.success(<Trans
                    i18nKey="Withdrawal.success"
                    values={{
                        asset: t("currency." + asset),
                        amount: amount.value,
                    }}
                />);

                refetchUserWallet()
                /*refetchTransactions()*/

                setInput({
                    /*amount: {value: "", error: []},*/
                    address: {value: "", error: []},
                    txPassword: {value: "", error: []},
                })

                setAmount({value: new BN(0), error: []})


            }).catch(err => {


                if (i18next.exists('ErrorMessages.'+ err?.response?.data?.message)) {
                    toast.error(t("ErrorMessages."+ err?.response?.data?.message))
                }
                else {
                    toast.error(t("serverError"))
                }


        }).finally(() => {

            setLoading(false)

        })

    }

    const validation = (value) => {

      /*  console.log("value", value)
        console.log("value BN", new BN(value))*/

        const newAmount = {...amount}
        newAmount.error = []


        /*console.log("new BN(freeWallet)", new BN(freeWallet))
        console.log("new", freeWallet)
        console.log("new BN(value)", new BN(value))
        console.log("value", value)*/


        if (new BN(value).isGreaterThan(new BN(freeWallet))) {
            newAmount.error = t('Withdrawal.noInventory')
        }

        if (new BN(value).isEqualTo(new BN(freeWallet).decimalPlaces(precision))) {
            newAmount.error = t('Withdrawal.noInventorywithdrawFee')
        }



        if (new BN(value).isLessThan(new BN(minWithdraw))) {
            newAmount.error = t('Withdrawal.allowableWithdraw')
        }

        if (new BN(value).minus(new BN(withdrawFee)).isLessThanOrEqualTo(0)) {
            newAmount.error = t('Withdrawal.noInventorywithdrawFee')
        }

        /*if (new BN(value).minus(new BN(withdrawFee)).isGreaterThanOrEqualTo(new BN(freeWallet))) {
            newAmount.error = t('Withdrawal.noInventorywithdrawFee')
            console.log("noInventorywithdrawFee")
        }*/

        /*if (new BN(value).minus(withdrawFee).isGreaterThanOrEqualTo(new BN(freeWallet))) {
            newAmount.error = t('Withdrawal.noInventorywithdrawFee')
            console.log("noInventorywithdrawFee")
        }*/
        if (new BN(value).isLessThan(new BN(withdrawFee)) ) {
            newAmount.error = t('Withdrawal.allowableWithdraw')
        }
        newAmount.value = new BN(value)
        setAmount(newAmount)
    }


    const fillByWallet = () => {

        validation(new BN(freeWallet).minus(new BN(withdrawFee)).decimalPlaces(precision))
        /*setAmount({
            value: new BN(freeWallet),
            error: []
        })*/
    };
    const fillByMinWithdraw = () => {

        validation(new BN(minWithdraw).plus(new BN(withdrawFee)).decimalPlaces(precision))
        /*setAmount({
            value: new BN(freeWallet),
            error: []
        })*/
    };

    const inputChangeHandler = (value) => {
        validation(parsePriceString(value))

    }

    const buttonTitleHandler = () => {
        if (loading) return <Loading type="text"/>
        return t('submit')
    }

    const content = () => {


        if (isLoading || userWalletIsLoading)  {
            return <>
                <div className={`${classes.rectangle2} width-100 rounded-8 my-1`}/>
                <div className={`${classes.rectangle2} width-100 rounded-8 my-1`}/>
                <div className={`${classes.rectangle2} width-100 rounded-8 my-1`}/>
            </>
        }
        if (error || userWalletError) {
            return <span className={`my-10`}>{t("error")}</span>
        }

        return <>


            <div className={`width-100 rounded-8 row jc-between ai-center ${classes.amountBox} px-5 py-1 my-1 position-relative `} onClick={() =>fillByWallet()}>
                <span className={`width-40`}>{t("Withdrawal.availableWithdrawalBalance")}</span>
                <div className={`width-60 row jc-end ai-center`}>
                    <span className={`fs-02 font-weight-bold`}>{new BN(freeWallet)?.decimalPlaces(precision).toFormat()}</span>
                    <span className={`${classes.space}`}>{global?.currencies[asset]?.alias}</span>
                </div>
            </div>
            <div className={`width-100 rounded-8 row jc-between ai-center ${classes.amountBox} px-5 py-1 my-1 position-relative `} onClick={() =>fillByMinWithdraw()}>
                <span className={`width-40`}>{t("Withdrawal.minWithdrawal")}</span>
                <div className={`width-60 row jc-end ai-center`}>
                    <span className={`fs-02 font-weight-bold`}>{new BN(minWithdraw)?.decimalPlaces(precision).toFormat()}</span>
                    <span className={`${classes.space}`}>{global?.currencies[asset]?.alias}</span>
                </div>
            </div>
            <div className={`width-100 rounded-8 row jc-between ai-center ${classes.amountBox} px-5 py-1 mt-1 mb-4 position-relative `}>
                <span className={`width-40`}>{t("commission")}</span>
                <div className={`width-60 row jc-end ai-center`}>
                    <span className={`fs-02 font-weight-bold`}>{new BN(withdrawFee)?.decimalPlaces(precision).toFormat()}</span>
                    <span className={`${classes.space}`}>{global?.currencies[asset]?.alias}</span>
                </div>
            </div>

            { withdrawEnabled ?
                <>

                    <NumberInput
                        value={amount.value.toFormat()}
                        type="text"
                        label={t('amount')}
                        //placeholder={t('Login.mobilePh')}

                        //maxLength="10"

                        onchange={(e) => inputChangeHandler(e.target.value)}
                        maxDecimal={precision}

                        alert={amount.error}
                        inputClass={`width-100 my-1`}
                        icon={
                            <span>{asset}</span>
                        }
                    />
                    <TextInput
                        value={input.address.value}
                        type="text"
                        label={t('address')}
                        id="address"
                        labelFor="address"
                        //placeholder={t('Login.mobilePh')}
                        data-name="address"
                        data-type="input"
                        data-min={2}
                        //maxLength="10"
                        onchange={(e) => inputHandler(e)}
                        alerts={input.address.error}
                        inputClass={`width-100 my-1`}
                    />

                    <div className={`width-100 rounded-8 row jc-between ai-center ${classes.amountBox} px-5 py-1 my-3 position-relative `}>
                        <span className={`width-40`}>{t("Withdrawal.yourReceive")}</span>
                        <div className={`width-60 row jc-end ai-center`}>
                            <span className={`fs-02 font-weight-bold`}>
                                {(new BN(amount.value).minus(withdrawFee).isGreaterThan(0) && new BN(freeWallet).isGreaterThan(0) && new BN(amount.value).isLessThan(new BN(freeWallet))) ? new BN(amount.value).minus(withdrawFee).decimalPlaces(precision).toFormat() : 0}
                            </span>
                            <span className={`${classes.space}`}>{global?.currencies[asset]?.alias}</span>
                        </div>
                    </div>


                    <TextInput
                        value={input.txPassword.value}
                        label={t('txPassword')}
                        autoComplete={false}

                        id="txPassword"
                        labelFor="txPassword"
                        //placeholder={t('Login.mobilePh')}
                        data-name="txPassword"
                        data-type="input"
                        data-min={8}
                        //maxLength="10"
                        onchange={(e) => inputHandler(e)}
                        alerts={input.txPassword.error}
                        inputClass={`width-100 my-2`}
                        type={isInputVisible.password ? "text" : "password"}
                        icon={
                            <Icon
                                iconName={`${isInputVisible.password ? ' icon-eye' : 'icon-eye-off'} fs-03 flex cursor-pointer hover-text`}
                                onClick={() => setIsInputVisible({
                                    ...isInputVisible,
                                    password: !isInputVisible.password
                                })}
                            />
                        }
                    />


                    <Button
                        type="button"
                        buttonClass={`${classes.thisButton} width-100 cursor-pointer rounded-100-p my-1`}
                        buttonTitle={buttonTitleHandler()}
                        disabled={!(new BN(amount.value).minus(withdrawFee).isGreaterThan(0)) || new BN(amount.value).isGreaterThan(freeWallet) || input?.address.value.length <= 0 || loading || amount.error.length > 0}
                        onClick={()=>submit()}
                    />

                </>

                :


                <div className={`width-100 rounded-8 row jc-center ai-center ${classes.deniedBox} px-5 py-1 mt-1 mb-4 position-relative`}>


                    <Trans
                        i18nKey="Withdrawal.withdrawDenied"
                        values={{
                            asset: global?.currencies[asset]?.alias,
                        }}
                    />
                </div>


            }





        </>

    }

    return (
        <div className={`width-86 column jc-center ai-center m-auto`}>
            {content()}
        </div>
    );
};

export default WithdrawalAddressByNetwork;



/*
const {auth, setAuth} = useAuth();

const {data, isLoading, error} = useGetCurrencies()
const {data: userWalletData, isLoading: userWalletIsLoading, error: userWalletError} = useGetUserWallet()


const [loading, setLoading] = useState(false)

const [isInputVisible, setIsInputVisible] = useState({
    password: false,
});


/!*console.log("data", data[asset])
console.log("userWalletData", userWalletData[asset])*!/

const precision = !isLoading && data[asset]?.precision
const freeWallet = !userWalletIsLoading && userWalletData[asset]?.balance || 0
const minWithdraw = !isLoading && data[asset]?.currencyImpData?.implementations[0]?.withdrawMin
const withdrawFee = !isLoading && data[asset]?.currencyImpData?.implementations[0]?.withdrawFee
const withdrawEnabled = !isLoading && data[asset]?.currencyImpData?.implementations[0]?.withdrawEnabled*/
