import React from 'react';
import classes from './CurrencyCard.module.css'
import {useTranslation} from "react-i18next";
import useAuth from "../../../../../../Hooks/useAuth";
import useGlobal from "../../../../../../Hooks/useGlobal";

const CurrencyCard = ({data}) => {

    const {t} = useTranslation();

    const {global, setGlobal} = useGlobal();

    const selectedCoinHandler = (coin) => {

        let newGlobal = {...global}

        newGlobal = {
            ...newGlobal,
            activeActionSheet: {
                menu: false,
                select: false,
            },
            selectType: null,
            selected_withdrawal_network: null,
            selected_withdrawal_iban: null,
            ["selected_" + global?.selectType]: coin
        }

        setGlobal(newGlobal)

    }

    return (
        <div className={`${classes.asset} row jc-between ai-center py-1 my-2 px-3 rounded-8`} onClick={()=>selectedCoinHandler(data?.symbol)}>
            <div className={`width-30 flex jc-start ai-center`}>
                <img src={data?.icon} alt="" className={`${classes.icon}`}/>
            </div>
            <div className={`width-35 row jc-start ai-center`}>
                <span className={`abridge`}>{data?.alias}</span>
            </div>
            <div className={`width-35 row jc-end ai-center`}>
                <span className={`text-gray`}>{data?.symbol}</span>
            </div>
        </div>
    );
};

export default CurrencyCard;
