import React, {useEffect} from 'react';
import classes from './Coin.module.css'
import {useTranslation} from "react-i18next";
import {BN, ratePrint} from "../../../../../../utils/utils";
import {useGetDashboard} from "../../../../../../query";
import {useNavigate} from "react-router-dom";
import * as RoutesName from "../../../../../../Routes/routes";
import {Markets} from "../../../../../../Routes/routes";
import useGlobal from "../../../../../../Hooks/useGlobal";


const Coin = ({data, index}) => {

    const {t} = useTranslation();
    const navigate = useNavigate();

    const {global} = useGlobal();

    const rateHandler = () => {

        if (!data?.rates) return <span>---</span>
        const newObject = [];
        Object.keys(data?.rates).forEach((key) => {



            newObject.push( <div className={`row `} key={key}><span className={`ml-1 text-gray fs-0-9`}>{key}</span>  <span>{data?.symbol === key ? 1 : data?.rates[key] !== null ? ratePrint(data?.rates[key], global?.currencies[key]?.precision) : "---"}</span>   </div>)
        });
        return newObject;
    }

    const navigateHandler = () => {

        /*setAuth({
            ...auth,
            backLocation: location?.search
        })*/
        navigate(RoutesName.Markets + data?.symbol)
    }

    return (
        <div className={`width-90 py-2 row jc-between ai-center ${classes.container}`} onClick={() => navigateHandler()}>
           {/* <div className={`${classes.circle} width-23 flex jc-start ai-center`}
                 style={{backgroundImage: `url("${data?.image_url}")`}}

            >

            </div>*/}

            <img src={data?.icon} alt="" className={`${classes.circle} ${classes.icon} width-23 flex jc-start ai-center`}/>

            <div className={`${classes.rectangle} width-76 row jc-between ai-center`}>
                <div className={`width-50 column jc-center ai-start`}>
                    <span className={``}>{data?.alias}</span>
                    <span className={``}>{data?.symbol}</span>
                </div>

                <div className={`width-50 column jc-center ai-end`}>
                    {rateHandler()}
                    {/*<span className={` ${classes.rate} fs-01`}>{data?.rate?.rate?.toLocaleString()}</span>*/}
                    {/*<span className={` text-gray`}>{data?.rate?.rate ? t("currency."+ data?.rate?.destSymbol) : "- - -"}</span>*/}
                    {/*<span className={` text-gray `}>{}</span>*/}

                </div>
            </div>
        </div>
    );
};

export default Coin;