import React, {useEffect, useState} from 'react';
import classes from "./ActionSheet.module.css";
import {useLocation} from "react-router-dom";
import useAuth from "../../Hooks/useAuth";
import Menu from "../../main/Mobile/Layout/Menu/Menu";
import Select from "../../main/Mobile/Layout/Select/Select";
import useGlobal from "../../Hooks/useGlobal";

const ActionSheet = () => {

    const location = useLocation();

    const {global, setGlobal} = useGlobal();


    const active = global?.activeActionSheet

    const [isFirst, setIsFirst] = useState(false);

    useEffect(() => {

        setGlobal({
            ...global,
            activeActionSheet: {
                menu: false,
                select: false,
            },
        })

    }, [location.pathname])

    const onClickHandler = () => {


        setGlobal({
            ...global,
            activeActionSheet: {
                menu: false,
                select: false,
            },
            selectType: null
        })
        setIsFirst(true)
    }


    const classHandler = () => {
        if (isFirst && (!active.menu && !active.select)) {
            return classes.close
        }
        if (active.menu || active.select) {
            return classes.show
        }
    }


    return (
        <>
            <div className={`width-100 ${classes.wrapper} ${(active.menu || active.select) && classes.show}`}
                 onClick={onClickHandler}/>
            <div className={`width-100 ${classes.container} column jc-end  ${classHandler()}`}>
                <div className={`${classes.header} flex jc-center ai-center py-2`} onClick={onClickHandler}><span/>
                </div>
                {active.menu && <Menu/>}
                {active.select && <Select/>}
            </div>
        </>
    );
};

export default ActionSheet;
