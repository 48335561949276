import React from 'react';
import classes from './AddAccount.module.css';
import ScrollBar from "../../../../../../components/ScrollBar";
import {Trans, useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useGetUserInfo} from "../../../../../../query";
import AddShaba from "../AddIban/AddIban";
import AddCard from "../AddCard/AddCard";
import Sample from "../../../Authentication/Module/Sample/Sample";
import ExpandCard from "../ExpandCard/ExpandCard";

const AddAccount = () => {

    const {t} = useTranslation();

    const {data} = useGetUserInfo()

    const addCardData = [
        {title: "addIban", content: <AddShaba/>, id:"1"},
        {title: "addCard", content: <AddCard/>, id:"2"},
    ]

    return (
        <div className={`${classes.container} width-86 height-100 rounded-8 mt-4 column jc-between ai-center`}>
            <ScrollBar>
                <div className={`column`}>
                    <div className={`rounded-8 flex jc-center ai-start ${classes.noteBox} px-5 py-2 mb-3`}>
                        <span className={``}>
                            <Trans
                                i18nKey="LinkedBankAccounts.newAccountContent"
                                values={{
                                    name: data ? data?.first_name + " " + data?.last_name : "---",
                                }}
                            />
                        </span>
                    </div>
                    {addCardData.map((data , index) => <ExpandCard data={data} index={index} key={index} />)}
                </div>
            </ScrollBar>
        </div>
    );
};

export default AddAccount;
