import React, {useEffect, useState} from 'react';
import classes from './WithdrawNetwork.module.css'
import TextInput from "../../../../../../components/TextInput/TextInput";
import {useTranslation} from "react-i18next";
import useGlobal from "../../../../../../Hooks/useGlobal";
import WithdrawalAddress from "../WithdrawalAddressByNetwork/WithdrawalAddressByNetwork";
import WithdrawMethod from "../WithdrawMethod/WithdrawMethod";
import WithdrawalAddressByNetwork from "../WithdrawalAddressByNetwork/WithdrawalAddressByNetwork";


const WithdrawNetwork = () => {

    const {t} = useTranslation();
    const {global, setGlobal} = useGlobal();

    const [input, setInput] = useState({
        network: {value: "", error: []},
    });

    useEffect(()=>{

        let newInputData = {...input}

        if (global?.selected_withdrawal_network) {
            newInputData = {...newInputData,
                network : { value: global?.selected_withdrawal_network, error: []},
            }
        }

        setInput(newInputData)

    },[global?.selectType])

    return (
        <>
            <TextInput
                value={input.network.value}
                // value={t('currency.'+ input.asset.value)}
                type="text"
                label={t('network')}
                //placeholder={t('Login.mobilePh')}
                data-name="network"
                data-type="input"
                data-min={2}
                //maxLength="10"
                /*onchange={(e) => inputHandler(e)}*/
                alerts={input.network.error}
                inputClass={`width-85 my-1`}
                select={true}
                readOnly={true}
                selectType="withdrawal_network"
            />


            { input?.network?.value && <WithdrawalAddressByNetwork/> }



        </>
    );
};

export default WithdrawNetwork;
