import React from 'react';
import classes from './LoadConfigLoading.module.css'
import packageJson from "../../../package.json";
import {toAbsoluteUrl} from "../../utils/utils";

const LoadConfigLoading = () => {
    return (
        <div className={`${classes.container} width-100 flex jc-center ai-center position-relative`}>
            <img src={toAbsoluteUrl('/assets/logo/whiteLogo.svg')} className={`${classes.logo} flashit`} alt=""/>

            <span className={`position-absolute ${classes.version} text-white`}>{packageJson.version}</span>
        </div>
    );
};

export default LoadConfigLoading;
