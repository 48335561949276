import React, {useEffect, useRef, useState} from 'react';
import classes from './UploadingPro.module.css';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import useAuth from "../../../../../../Hooks/useAuth";
import {sendFile, sendMessage} from "../../../../../../api/auth";
import toast from "react-hot-toast";
import {useGetMessages} from "../../../../../../query";
import Icons from "../../../../../../components/Icon/Icons";
import Attach from "../Attach/Attach";
import {images} from "../../../../../../assets/images/images";
import i18next from "i18next";

const UploadingPro = () => {

    const {t} = useTranslation();
    const navigate = useNavigate();

    const {auth} = useAuth();

    const [hasValue, setHasValue] = useState(false)
    const [sending, setSending] = useState(false)

    const [imagesInput, setImagesInput] = useState({
        img1: "",
    });

    useEffect(()=>{
        if (imagesInput?.img1) setHasValue(true)
    }, [imagesInput])

    const {refetch} = useGetMessages()

    const inputRef = useRef(null)

    const send = async (e) => {

        const sendMessageParam = {
            "text": inputRef?.current?.innerText,
        }

        if ((sendMessageParam?.text?.length <= 0) && !(imagesInput?.img1)) {
            return toast.error(t("Chat.emptyInput"))
        }

        setSending(true)

        if (imagesInput?.img1) {
            sendFile(imagesInput?.img1, inputRef?.current?.innerText, auth?.token)
                .then(async (res) => {
                    refetch()
                }).catch(err => {
                    if (i18next.exists('ErrorMessages.'+ err?.response?.data?.message)) {
                        toast.error(t("ErrorMessages."+ err?.response?.data?.message))
                    }
                    else {
                        toast.error(t("serverError"))
                    }
            }).finally(() => {
                inputRef.current.innerText = "";
                setImagesInput({
                    img1: "",
                })
                setHasValue(false)
                setSending(false)
            })
        }
        else {
            sendMessage(sendMessageParam)
                .then(async (res) => {
                    refetch()
                }).catch(err => {
                    if (i18next.exists('ErrorMessages.'+ err?.response?.data?.message)) {
                        toast.error(t("ErrorMessages."+ err?.response?.data?.message))
                    }
                    else {
                        toast.error(t("serverError"))
                    }
            }).finally(() => {
                inputRef.current.innerText = "";
                setHasValue(false)
                setSending(false)
            })
        }
    }

    const keyUp = () => {
        let value = inputRef?.current?.innerText !== ""
        if (value) return  setHasValue(true)
        if (!value) return  setHasValue(false)
        else return setHasValue(false)
    }

    const removeFileHandler = (e) => {
        e.stopPropagation();
      setImagesInput({
          img1: "",
      })
        setHasValue(false)
    }

    return (
        <form className={`${classes.container} width-100 row jc-between ai-end mt-1`}>
            <span className={`bg-light-blue-alpha rounded-100-p flex jc-center ai-center ${classes.icon} width-15`}>
               {
                   hasValue
                   ?
                       <>
                           {
                               sending ?
                                   <img src={images.sending} className={`${classes.sendingIcon}`} alt=""/>
                               :
                                   <Icons
                                       iconName={` icon-send fs-20 text-blue flex jc-center ai-center ${classes.iconSpace}`}
                                       iconClass={`cursor-pointer`}
                                       onClick={()=>send()}
                                   />
                           }
                       </>
                   :
                   <Attach
                   zoneCustomClass={classes.zoneBox}
                   title={t("UploadImages.acceptFormDropzone")}
                   onchange={(url) => setImagesInput({...imagesInput, img1: url})}
                   setImage={setImagesInput}
                   />
               }
            </span>
           <div className={`column width-85`}>
               {imagesInput?.img1?.preview && <div className={`${classes.image} position-relative rounded-4 flex jc-start ai-start mb-1`} style={{backgroundImage: `url("${imagesInput?.img1?.preview}")`}}>
                   <Icons
                       iconName="icon-cancel-circled text-red fs-05"
                       iconClass={`position-absolute ${classes.cancel} cursor-pointer`}
                       onClick={(e)=>removeFileHandler(e)}
                       /*onClick={(e) => {
                           e.stopPropagation();
                           setFiles([]);
                           props.onchange("");
                       }}*/
                   />
               </div>}

               <div className={`${classes.Input} position-relative rounded-4 px-2`}
                    id="input"
                    ref={inputRef}
                    contentEditable
                   //contentEditable={(!imagesInput?.img1) && true}
                    onKeyUp={(e)=>keyUp()}
               >
               </div>
           </div>
        </form>
    );
};

export default UploadingPro;
