import {useQuery, QueryCache} from "@tanstack/react-query";
import useAxiosPrivate from "../../Hooks/useAxiosPrivate";
import useAuth from "../../Hooks/useAuth";
import {useGetDashboard} from "./useGetDashboard";
import {BN} from "../../utils/utils";
import useGlobal from "../../Hooks/useGlobal";


export const useGetUserWallet = () => {
    const axiosPrivate =  useAxiosPrivate()

    const {auth} = useAuth();
    const {global} = useGlobal();

    const dashboard = global?.currencies


    return useQuery(
        ['user-wallet', auth?.mobile],
        async () => {
            const {data} = await axiosPrivate.get(`/api/v1/wallets`)
            const wallet = {}
            data?.data?.forEach((curr) => {
                wallet[curr.asset] = curr
            })

/*            Object.keys(wallet).forEach((key) => {
                Object.keys(dashboard[key].rates).forEach((rate) =>{
                    wallet[key][rate+"to"] = 1
                })
            });*/

            wallet["total"] = {}

            Object.keys(dashboard).forEach(key =>{
                if (!wallet.hasOwnProperty(key)){
                    wallet[key] = {
                        asset : key,
                        balance : 0 ,
                        locked:0,
                        withdraw:0
                    }
                    wallet[key]["equivalent"] = {}
                    Object.keys(dashboard[key].rates).forEach((rate) =>{
                        const equivalent = new BN(0)
                        wallet[key]["equivalent"][rate] = equivalent.toString()
                        wallet["total"][rate] = equivalent.plus(wallet["total"][rate] || 0).toString()
                    })

                }else {
                    wallet[key]["equivalent"] = {}
                    Object.keys(dashboard[key].rates).forEach((rate) =>{
                        let equivalent;
                        if (key === rate){
                            equivalent = new BN(wallet[key].balance)
                        }else{
                            equivalent = new BN(wallet[key].balance).multipliedBy(dashboard[key].rates[rate] === null ? 0 : dashboard[key].rates[rate])
                        }
                        wallet[key]["equivalent"][rate] = equivalent.toString()


                        wallet["total"][rate] = equivalent.plus(wallet["total"][rate] || 0).toString()

                    })
                }

            })

            /*console.log(wallet)*/

            return wallet;
        },
        {
            staleTime: 5 * 60 * 1000,
            // initialData: {},
            enabled : !!dashboard
        });
}