import {useQuery} from "@tanstack/react-query";
import axios from "axios";
import useAxiosPrivate from "../../Hooks/useAxiosPrivate";

export const useGetKycStatus = (config = {}) => {
    const axiosPrivate =  useAxiosPrivate()
    return useQuery(
        ['kyc-status'],
        async () => {
            const {data} = await axiosPrivate.get(`/api/v1/kyc/status`)

            return data?.data;
        },
        config);
}