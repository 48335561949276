import React, {useState} from 'react';
import classes from './RecentTxHistory.module.css';
import {useTranslation} from "react-i18next";
import {useGetTransactions} from "../../../../../../query";
import RecentTxHistoryCard from "../RecentTxHistoryCard/RecentTxHistoryCard";
import moment from "moment-jalaali";
import {Link} from "react-router-dom";
import Icons from "../../../../../../components/Icon/Icons";
import i18n from "i18next";
import * as Routes from "../../../../../../Routes/routes";

const RecentTxHistory = () => {

    const {t} = useTranslation();

    const [query, setQuery] = useState({
        "coin": null, // optional
        "category": null, // optional [DEPOSIT, FEE, TRADE, WITHDRAW, ORDER_CANCEL, ORDER_CREATE, ORDER_FINALIZED]
        /*"startTime": moment().subtract(1, 'months').startOf("day").valueOf(),
        "endTime": moment().endOf("day").valueOf(),*/
        "startTime": null,
        "endTime": null,
        "ascendingByTime": false,
        "limit": 10,
        "offset": 0
    });

    const {data, isLoading, error} = useGetTransactions(query)

    const content = () => {
        /* if (isLoading) {
             return <span className={`my-10`}>{t("loading")}</span>
         }*/
        if (isLoading) {
            return <div className={`width-100 column jc-center ai-center height-100 my-1`}>
                <div className={`width-90 my-1 row jc-between ai-center`}>
                    <div className={`${classes.circle}`}/>
                    <div className={`${classes.rectangle} width-76 rounded-8`}/>
                </div>
                <div className={`width-90 my-1 row jc-between ai-center`}>
                    <div className={`${classes.circle}`}/>
                    <div className={`${classes.rectangle} width-76 rounded-8`}/>
                </div>
                <div className={`width-90 my-1 row jc-between ai-center`}>
                    <div className={`${classes.circle}`}/>
                    <div className={`${classes.rectangle} width-76 rounded-8`}/>
                </div>
            </div>
        }
        if (error) {
            return <span className={`my-10`}>{t("error")}</span>
        }
        if (data?.length <= 0) {
            return <span className={`my-10`}>{t("noData")}</span>
        }
        return <>

            <div className={`width-100 ${classes.header}`}>
               <div className={`width-90 row jc-between ai-center m-auto py-2`}>
                   <span className={`font-weight-bold fs-02`}>{t("10RecentTxs")}</span>
                   <Link to={Routes.TransactionsHistory} className={`row jc-center ai-center cursor-pointer hover-text`}>
                       <span className={`ml-05`}>{t("viewAllTxs")}</span>
                       <Icons
                           iconName={`${i18n.language !== "fa" ? 'icon-right-open-1' : 'icon-left-open-1'} flex`}
                           className={`mr-05`}/>
                   </Link>
               </div>


            </div>

            {/*{
                data?.filter((f) => f.wallet === "main").map( (c , index) =>
                    <RecentTxHistoryCard data={c} key={index} index={index}/>
                )
            }*/}
            {
                data?.map( (c , index) =>
                    <RecentTxHistoryCard data={c} key={index} index={index}/>
                )
            }
        </>



    }

    return (
        <div className={`width-86 ${classes.container} mt-4 mb-4 column jc-center ai-center rounded-8`}>
            {content()}
        </div>
    );
};

export default RecentTxHistory;
