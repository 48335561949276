import React, {useEffect, useState} from 'react';
import classes from './Authentication.module.css'
import * as RoutesName from "../../../../Routes/routes";
import ScrollBar from "../../../../components/ScrollBar";
import {useTranslation} from "react-i18next";
import Button from "../../../../components/Button/Button";
import Icons from "../../../../components/Icon/Icons";
import Sample from "./Module/Sample/Sample";
import {useNavigate} from "react-router-dom";
import {useGetCurrencies, useGetKycStatus} from "../../../../query";
import Guide from "./Module/Guide/Guide";
import KYCStatus from "./Module/KYCStatus/KYCStatus";

const Authentication = () => {


    const navigate = useNavigate();

    const {data, isLoading, error} = useGetKycStatus()


    useEffect(() => {

        if (data?.kyc_status === "NoKYC") {
            return navigate(RoutesName.Guide)
        }
        else return navigate(RoutesName.KYCStatus)

    }, [data?.kyc_status])

};

export default Authentication;
