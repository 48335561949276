import React from 'react';
import classes from './KYCStatus.module.css'
import {useNavigate} from "react-router-dom";
import {useGetKycStatus} from "../../../../../../query";
import {images} from "../../../../../../assets/images/images";
import {useTranslation} from "react-i18next";
import Button from "../../../../../../components/Button/Button";
import ScrollBar from "../../../../../../components/ScrollBar";
import * as RoutesName from "../../../../../../Routes/routes";
import Loading from "../../../../../../components/Loading/Loading";
import Icons from "../../../../../../components/Icon/Icons";

const KYCStatus = () => {

    const {t} = useTranslation();
    const navigate = useNavigate();

    const {data, isLoading, error, refetch} = useGetKycStatus()

    const briefStatusSection = () => {
        return <div className={`${classes[data?.kyc_status]} ${classes.statusBox} row jc-between ai-center width-86 rounded-8`}>

            <span className={`width-80`}>{t("KYCStatus."+ data?.kyc_status)}</span>
            <img className={`${classes.statusImg}`} src={images[data?.kyc_status]} alt="kyc-rejected"/>
        </div>
    }
    const reportStatusSection = () => {

        if (data?.kyc_status === "Pending") return false;

        return <div className={`width-86 my-2`}>

            <div className={`mb-2 width-100 column jc-start`}>
                <span className={`font-weight-bold`}>{t("Authentication.adminText")}:</span>
                <span>{data?.admin_text?.admin_text ? data?.admin_text?.admin_text : "---"}</span>
            </div>

            <div className={`rounded-8 flex jc-center ai-center ${classes.noteBox} px-1 py-1 mt-1 width-100`}>
                <span>{t("Authentication.errorReport")}</span>
            </div>

            <div className={`column width-100 mb-3`}>
                <div className={`column width-100 my-2`}>
                    <div className={`row jc-start ai-center ${data?.admin_text?.accept_form_img === "accepted" ? 'text-green' : 'text-red'}`}>
                        <Icons iconName={`${data?.admin_text?.accept_form_img === "accepted" ? 'icon-ok' : 'icon-cancel'} flex jc-center ai-center fs-02`}/>
                        <span className={`${classes.title} font-weight-bold fs-01`}>{t("UploadImages.acceptFormDropzone")}</span>
                    </div>

                    <div className={`column jc-start ai-start ${classes.nav}`}>
                        <span className={`mt-1 mb-2`}>{t("AuthenticationHint." + data?.admin_text?.accept_form_img)}</span>

                        { data?.accept_form_img ?
                            <img src={data?.accept_form_img} alt="" className={`width-75 rounded-8 ${classes.image}`}/>
                            :
                            <div className={`${classes.imageWrapper} width-75 rounded-8 flex jc-center ai-center`}>
                                <img src={images.ETC} alt="" className={`${classes.blankImage}`} />
                            </div>
                        }
                    </div>
                </div>
                <div className={`column width-100 my-2`}>
                    <div className={`row jc-start ai-center ${data?.admin_text?.selfie_img === "accepted" ? 'text-green' : 'text-red'}`}>
                        <Icons iconName={`${data?.admin_text?.selfie_img === "accepted" ? 'icon-ok' : 'icon-cancel'} flex jc-center ai-center fs-02`}/>
                        <span className={`${classes.title} font-weight-bold fs-01`}>{t("UploadImages.selfieDropzone")}</span>
                    </div>

                    <div className={`column jc-start ai-start ${classes.nav}`}>
                        <span className={`mt-1 mb-2`}>{t("AuthenticationHint." + data?.admin_text?.selfie_img)}</span>

                        { data?.selfie_img ?
                            <img src={data?.selfie_img} alt="" className={`width-75 rounded-8 ${classes.image}`}/>
                            :
                            <div className={`${classes.imageWrapper} width-75 rounded-8 flex jc-center ai-center`}>
                                <img src={images.ETC} alt="" className={`${classes.blankImage}`} />
                            </div>
                        }


                    </div>
                </div>
                <div className={`column width-100 my-2`}>
                    <div className={`row jc-start ai-center ${data?.admin_text?.id_card_img === "accepted" ? 'text-green' : 'text-red'}`}>
                        <Icons iconName={`${data?.admin_text?.id_card_img === "accepted" ? 'icon-ok' : 'icon-cancel'} flex jc-center ai-center fs-02`}/>
                        <span className={`${classes.title} font-weight-bold fs-01`}>{t("UploadImages.idCardDropzone")}</span>
                    </div>

                    <div className={`column jc-start ai-start ${classes.nav}`}>
                        <span className={`mt-1 mb-2`}>{t("AuthenticationHint." + data?.admin_text?.id_card_img)}</span>

                        { data?.id_card_img ?
                            <img src={data?.id_card_img} alt="" className={`width-75 rounded-8 ${classes.image}`}/>
                            :
                            <div className={`${classes.imageWrapper} width-75 rounded-8 flex jc-center ai-center`}>
                                <img src={images.ETC} alt="" className={`${classes.blankImage}`} />
                            </div>
                        }

                    </div>
                </div>
            </div>
        </div>

    }

    const buttonSection = () => {

        if (data?.kyc_status === "Pending" || data?.kyc_status === "Blocked") return false;


        return <Button
            type="button"
            buttonClass={`${classes.thisButton} width-100 cursor-pointer rounded-100-p mb-2`}
            buttonTitle={data?.kyc_status === "Rejected" ? t('retry') : t('home')}
            onClick={()=>navigate(data?.kyc_status === "Rejected" ? RoutesName.UploadImages : RoutesName.Home )}
        />
    }


    if  (isLoading) {
        return <Loading type="text"/>
    }
    if  (error) {
        return <div className={`width-100 column jc-center ai-center height-100`}>

            <Button
                type="submit"
                buttonClass={`${classes.thisButton} cursor-pointer rounded-100-p mb-1 px-6`}
                buttonTitle={t('retry')}
                onClick={()=>refetch()}
            />
            <span className={`mt-1`}>{t("error")}</span>
        </div>

    }


    return (
        <ScrollBar>
            <div className={`${classes.container} column jc-between ai-center pb-3 pt-3 px-5`} >
                {briefStatusSection()}
                {reportStatusSection()}
                {buttonSection()}
            </div>
        </ScrollBar>
    );
};

export default KYCStatus;
