import React from 'react';
import {isBrowser, isMobile, isTablet} from "react-device-detect";

import Mobile from "./Mobile/Mobile";
import Browser from "./Browser/Browser";
import axios from "axios";
import setupAxios from "../setup/axios/setupAxios";
import useAuth from "../Hooks/useAuth";

const Main = () => {


   /* setupAxios(axios);
*/
    const detectDevice = () => {
        if (isBrowser) return <Mobile/>
        if (isTablet) return <Mobile/>
        if (isMobile) return <Mobile/>
    }

    return detectDevice()


};

export default Main;
