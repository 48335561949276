import React, {useState} from 'react';
import classes from './Password.module.css'
import {Trans, useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import * as RoutesName from "../../../../Routes/routes";
import Button from "../../../../components/Button/Button";
import TextInput from "../../../../components/TextInput/TextInput";
import useAuth from "../../../../Hooks/useAuth";
import {login, requestOTP} from "../../../../api/auth";
import toast from "react-hot-toast";
import Loading from "../../../../components/Loading/Loading";
import Icon from "../../../../components/Icon/Icons";
import Countdown from "react-countdown";
import useGlobal from "../../../../Hooks/useGlobal";
import i18next from "i18next";

const Password = () => {


    const {t} = useTranslation();
    const navigate = useNavigate();

    const {auth, setAuth} = useAuth();
    const {global, setGlobal} = useGlobal();

    const [loading, setLoading] = useState(false)
    const [forgetPassLoading, setForgetPassLoading] = useState(false)


    const [input, setInput] = useState({
        password: {value: "", error: []},
    });

    const [isInputVisible, setIsInputVisible] = useState({
        password: false,
    });

    const inputHandler = (e) => {
        let errorMessage = []
        let inputVal = e.target.value
        if (typeof e.target.dataset.min !== undefined && e.target.value.length < e.target.dataset.min) {
            errorMessage.push(<Trans
                i18nKey="Login.minInput"
                values={{
                    name: t(e.target.dataset.name),
                    min: e.target.dataset.min
                }}
            />)
        }
        let prevState = {
            ...input,
            [e.target.dataset.name]: {...input[e.target.dataset.name], value: inputVal, error: errorMessage}
        }
        setInput(prevState)
    }

    const isFormValid = () => {
        let inputs = {...input}

        const hasError = Object.values(input).find(input => input.error.length > 0)
        if (hasError) return false
        let isEmpty = false

        for (const key in inputs) {
            if (inputs[key].value.length === 0) {
                isEmpty = true
                inputs = {
                    ...inputs,
                    [key]: {
                        ...inputs[key],
                        error: [<Trans
                            i18nKey="Login.emptyInput"
                            values={{
                                name: t(key),
                            }}
                        />]
                    }
                }
            }
        }

        setInput(inputs);
        return !isEmpty;
    }

    const submit = async (e) => {
        e.preventDefault();

        if (!isFormValid()) return false;

        setLoading(true)

        login(auth?.mobile, input?.password?.value)
            .then(async (res) => {


                setAuth({...auth,
                    token: res?.data?.data?.access_token,
                })


                navigate(RoutesName.Home)

            }).catch(err => {
                if (i18next.exists('ErrorMessages.'+ err?.response?.data?.message)) {
                    toast.error(t("ErrorMessages."+ err?.response?.data?.message))
                }
                else {
                    toast.error(t("serverError"))
                }

        }).finally(() => {

            setLoading(false)

        })


        /*if (!input.otp.value) {
            return setInput({...input, otp:{...input.otp, error: [`${t("Otp.enterCode")}`] } })
        }*/
        //if (!isFormValid()) return false;

    }

    const buttonTitleHandler = () => {
        if (loading) return <Loading type="text"/>
        return t('submit')
    }

    const sendForgetPasswordOtpReq =  async () => {
        setForgetPassLoading(true)
        requestOTP(auth?.mobile, true)
            .then(async (res) => {

                setGlobal({...global,
                    otpLock: new Date().getTime() + 2 * 60 * 1000,
                })

                navigate(RoutesName.ForgetPassword)


            }).catch(err => {
            toast.error(err?.response?.data?.message)
        }).finally(() => {
            //setLoading(false)
            setForgetPassLoading(false)
        })
    }

    if (forgetPassLoading) return <form onSubmit={(e)=>submit(e)} className={`${classes.container} height-100 column jc-between ai-center py-3 px-5`}>

        <Loading type={"text"}/>
    </form>


    return (
        <form onSubmit={(e)=>submit(e)} className={`${classes.container} height-100 column jc-between ai-center py-3 px-5`}>




            <span className={`fs-01 mt-3 mb-1`}>

                    <Trans
                        i18nKey="Password.text"
                        values={{
                            mobile: auth?.mobile,
                        }}
                    />

                </span>


           <div className={`width-100 column jc-start ai-center mb-10`}>
               <TextInput
                   value={input.password.value}
                   label={t('password')}


                   id="password"
                   labelFor="password"

                   //placeholder={t('Login.mobilePh')}
                   data-name="password"
                   data-type="input"
                   data-min={8}
                   //maxLength="10"
                   onchange={(e) => inputHandler(e)}
                   alerts={input.password.error}
                   inputClass={`width-85 `}
                   type={isInputVisible.password ? "text" : "password"}
                   icon={
                       <Icon
                           iconName={`${isInputVisible.password ? ' icon-eye' : 'icon-eye-off'} fs-03 flex cursor-pointer hover-text`}
                           onClick={() => setIsInputVisible({
                               ...isInputVisible,
                               password: !isInputVisible.password
                           })}
                       />
                   }
               />


               <span className={`flex row jc-center mt-5`} onClick={()=>sendForgetPasswordOtpReq()}>{t('forgetPassword')}</span>
           </div>



            <Button
                type="submit"
                buttonClass={`${classes.thisButton} width-100 cursor-pointer rounded-100-p mb-1`}
                buttonTitle={buttonTitleHandler()}
                disabled={loading}
            />
        </form>
    );
};

export default Password;
