import React, {useState} from 'react';
import classes from './ExpandCard.module.css'
import {useTranslation} from "react-i18next";
import Icons from "../../../../../../components/Icon/Icons";
import Loading from "../../../../../../components/Loading/Loading";

const ExpandCard = ({data}) => {

    const {t} = useTranslation();

    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className={`${classes.container} width-100 rounded-8 column mb-2`}>
            <div className={`row jc-between ai-center `} onClick={()=>setIsOpen(prevState => !prevState)}>
                <span>{t("LinkedBankAccounts."+ data?.title)}</span>
                <Icons
                    iconName={`${isOpen ? 'icon-up-open' : 'icon-down-open'} flex fs-04`}
                    iconClass={`cursor-pointer`}
                />
            </div>
            {isOpen && <div className={`column jc-center ai-center mt-2`}>
                {data?.content}
            </div>}
        </div>
    );
};

export default ExpandCard;
