import React from "react";
import {images} from "../../assets/images/images";
import classes from './Loading.module.css'
import {useTranslation} from "react-i18next";

const Loading = ({type}) => {

    const {t} = useTranslation();

    const content = () => {
        if (type === "dots") return <img
            className={`${classes.spinner}`} src={images.dotsLoading} alt="loading..."/>

        if (type === "spinner") return <img
            className={`${classes.spinner}`} src={images.spinnerLoading} alt="loading..."/>

        if (type === "linear") return <img
            className={`${classes.image}`} src={images.linearLoading} alt="loading..."/>

        if (type === "isFullPage") return <img
        className={`${classes.imageFullPage}`} src={images.linearLoading} alt="loading..."/>

        if (type === "text") return <span className={`flashit`}>{t("loading")}</span>

        return <img className={`${classes.image}`} src={images.linearLoading} alt="loading..."/>
    }

    return (
        <div className="container column ai-center jc-center" style={{height: type === "isFullPage" ? "100vh" : "100%"}}>
            {content()}
        </div>
    );
};

export default Loading;