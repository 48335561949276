import React from 'react';
import classes from './Home.module.css';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import Info from "./Module/Info/Info";
import ScrollBar from "../../../../components/ScrollBar";
import Content from "./Module/Content/Content";

const Home = () => {

    const {t} = useTranslation();
    const navigate = useNavigate();

    return (
        <div className={`${classes.container} column height-100`}>
            <ScrollBar>
                <Info/>
                <Content/>
            </ScrollBar>
        </div>
    );
};

export default Home;
