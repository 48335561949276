import React, {useState} from 'react';
import  classes from './Transfer.module.css';
import ScrollBar from "../../../../components/ScrollBar";
import Info from "../Home/Module/Info/Info";
import Content from "../Home/Module/Content/Content";
import {useTranslation} from "react-i18next";
import LastPrice from "../Home/Module/LastPrice/LastPrice";
import RecentTxHistory from "../Home/Module/RecentTxHistory/RecentTxHistory";
import Wallet from "../Home/Module/Wallet/Wallet";
import Payment from "./Module/Payment/Payment";
import Receive from "./Module/Receive/Receive";
import NavBar from "../../../../components/NavBar/NavBar";

const Transfer = () => {

    const {t} = useTranslation();

    const [step, setStep] = useState(1)

    const tab = [
        {id: 1, title: t("Receive.title"), action:  () => setStep(1)},
        {id: 2, title: t("Payment.title"), action:() => setStep(2)},
    ];

    const content = () => {

        if (step === 1) {
            return <Receive/>
        }
        if (step === 2) {
            return <Payment/>
        }
    }

    return (
        <div className={`${classes.container} column height-100 pt-3 pb-5 width-100 column jc-start ai-center`}>


                   <NavBar
                       tab={tab}
                       navClass={`width-86`}
                   />
                   {content()}





        </div>
    );
};

export default Transfer;
