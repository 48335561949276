import React, {useEffect, useRef} from 'react';
import classes from './Chat.module.css'
import Uploading from "./Module/Uploading/Uploading";
import Messages from "./Module/Messages/Messages";
import UploadingPro from "./Module/UploadingPro/UploadingPro";

const Chat = () => {

    const outerHeight = useRef(null)

    
    return (
        <div className={`${classes.container} column jc-end ai-center pb-2 px-5`} ref={outerHeight}>

            <Messages/>
            <UploadingPro/>

        </div>
    );
};

export default Chat;
