import {useQuery} from "@tanstack/react-query";
import useAxiosPrivate from "../../Hooks/useAxiosPrivate";
import useAuth from "../../Hooks/useAuth";


export const useGetDepositAddress = (currency, network) => {

    const axiosPrivate =  useAxiosPrivate()
    const {auth} = useAuth();

    return useQuery(
        ['depositAddress', auth?.mobile, currency, network],
        async () => {

            const params = new URLSearchParams();
            params.append('currency', currency.toUpperCase());
            params.append('chain', network);
            const {data} = await axiosPrivate.post(`/api/v1/address?${params.toString()}`, {
                data: params,
                headers: {
                    'content-type': 'application/x-www-form-urlencoded'
                }
            })

            return data?.data;
        },
        {
            retry: 1,
            refetchOnMount: false,
            staleTime: 30*60*1000,
        });
}

/*
const getDepositAddressFunc = async (currency, network) => {





    return axiosPrivate.get(`/api/v1/address?${params.toString()}`, {
        data: params,
        headers: {
            'content-type': 'application/x-www-form-urlencoded'
        }
    })

    return data;
}




return useQuery(
    ['depositAddress', auth?.mobile, currency, network],
    () => getDepositAddressFunc(currency, network),
    {
        retry: 1,
        refetchOnMount: false,
        staleTime: 30*60*1000,
    });*/
