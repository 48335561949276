import React, {useEffect, useState} from 'react';
import classes from './Attach.module.css'
import Icons from "../../../../../../components/Icon/Icons";
import {useDropzone} from "react-dropzone";
import {useTranslation} from "react-i18next";
import toast from "react-hot-toast";

const Attach = (props) => {

    const {t} = useTranslation();

    const [files, setFiles] = useState([]);
    const {getRootProps, getInputProps, fileRejections} = useDropzone({
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg'],
        },
        maxFiles: 1,
        maxSize: 1000000,
        multiple: false,
        onDrop: (acceptedFiles) => {
            if (acceptedFiles.length > 0) {
                props.onchange(acceptedFiles[0]);
                setFiles([
                    Object.assign(acceptedFiles[0], {
                        preview: URL.createObjectURL(acceptedFiles[0]),
                    }),
                ]);
            } else {
                setFiles([]);
            }
        },
    });

    useEffect(
        () => () => {
            files.forEach((file) => URL.revokeObjectURL(file.preview));
        },
        [files],
    );

    const errorMsg = (code) => {
        switch (code) {
            case "file-too-large":
                return t("ImageInput.fileTooLarge");
            case "file-invalid-type":
                return t("ImageInput.fileInvalidType");
            case "too-many-files":
                return t("ImageInput.tooManyFiles");
            default:
                return t("ImageInput.errorMsgDefault");
        }
    };




    const fileRejectionItems = fileRejections.slice(0, 1).map(({errors}) =>
        errors.map((e) => (
            <div className={`row fs-0-6 mt-1`} key={e.code}>
        <span
            onClick={(e) => {
                e.stopPropagation();
            }}
            data-html={true}
            data-place="bottom"
            data-effect="float"
            data-tip={`<span class="column jc-between col-100">${t(
                "ImageInput.iconErrorText",
            )}</span>`}>
          <Icons
              iconName="icon-info-circled text-red fs-01"
              iconClass={`cursor-pointer`}
          />
        </span>
            </div>
        )),
    );

    useEffect(() => {
        if (fileRejections?.[0]?.errors?.[0]?.code ) {
            toast.error(`${errorMsg(fileRejections?.[0]?.errors?.[0]?.code)}`)
        }
    }, [fileRejections]);


    return (<>
            <section className={`${props.zoneCustomClass} my-1`}>
                <div
                    {...getRootProps({
                        className: `dropzone flex jc-center ai-center text-center position-relative cursor-pointer hover-text ${classes.zone}`,
                    })}
                >
                    <input {...getInputProps()} />
                    <Icons
                        iconName={`icon-attach fs-20 text-blue flex jc-center ai-center ${classes.iconSpace}`}
                        iconClass={`cursor-pointer`}
                    />
                </div>
            </section>
    </>

    );
};

export default Attach;
