import React from 'react';
import moment from "moment-jalaali";

const Date = ({date}) => {

    const type = window.env.REACT_APP_CALANDAR_TYPE

    const calendar = () => {

        switch (type) {
            case "Jalali":
                return moment(date).format("jYY/jMM/jDD");
            case "Hijri":
                return moment(date).format("YY/MM/DD");
            case "Georgian":
                return moment(date).format("iYY/iMM/iDD");
            default:
                return moment(date).format("YY/MM/DD");
        }
    };

    return (<>{calendar()}</>);
};

export default Date;
