import React from 'react';
import * as RoutesName from "../../Routes/routes";

import {useNavigate} from "react-router-dom";
import Button from "../Button/Button";
import classes from "./Unauthorized.module.css";
import {images} from "../../assets/images/images";
import {useTranslation} from "react-i18next";
import {loadConfig} from "../../utils/loadConfig";

const Unauthorized = () => {

    const {t} = useTranslation();
    const navigate = useNavigate();

    return (
        <div className={`${classes.container} width-100 column jc-center ai-center`}>
            <img src={images.unauthorized} className={`${classes.logo}`} alt=""/>

            <span className={`mb-3 font-weight-bold text-red`}>{t("unauthorized")}</span>

            <Button
                type="button"
                buttonClass={`${classes.thisButton} cursor-pointer rounded-100-p mt-3`}
                buttonTitle={t("home")}
                onClick={()=> {navigate(RoutesName.Home)}}
            />

        </div>


        /*<div className={`height-100 column jc-center ai-center`} style={{height: '100vh'}}>
            <img className={`mb-2`} src={images.notFound} alt="404" style={{width: "7vw"}}/>
            <span className={`my-2 font-weight-bold fs-02 text-red`}>صفحه مورد نظر پیدا نشد...!</span>
            <Button
                buttonClass={`${classes.thisButton} cursor-pointer mt-5 px-1`}
                type="submit"
                buttonTitle="صفحه اصلی"
                onClick={() => navigate(RoutesName.Home)}
            />
        </div>*/
    );
};

export default Unauthorized;
