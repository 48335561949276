import React from 'react';
import classes from './WithdrawMethod.module.css';
import {useTranslation} from "react-i18next";
import useAuth from "../../../../../../Hooks/useAuth";
import useGlobal from "../../../../../../Hooks/useGlobal";
import {useGetCurrencies} from "../../../../../../query";
import WithdrawalAddress from "../WithdrawalAddressByNetwork/WithdrawalAddressByNetwork";
import WithdrawNetwork from "../WithdrawNetwork/WithdrawNetwork";
import WithdrawBankAccount from "../WithdrawBankAccount/WithdrawBankAccount";

const WithdrawMethod = ({asset}) => {

    const {t} = useTranslation();

    const {auth} = useAuth();
    const {global} = useGlobal();

    const {data, isLoading, error} = useGetCurrencies(asset)

    console.log("asset",asset)
    console.log("length", !isLoading && Object?.keys(data?.impls)?.length)

    const content = () => {

        if (isLoading)  {
            return <>
                <div className={`${classes.rectangle2} width-85 rounded-8 my-1`}/>
                <div className={`${classes.rectangle2} width-85 rounded-8 my-1`}/>
                <div className={`${classes.rectangle2} width-85 rounded-8 my-1`}/>
            </>
        }
        if (error) {
            return <span className={`my-10`}>{t("error")}</span>
        }

        if (Object?.keys(data?.impls)?.length > 0) {
            return <WithdrawNetwork/>
        }
        if (Object?.keys(data?.impls)?.length === 0) {
            return <WithdrawBankAccount/>
        }

    }


    return (
        <>
            {content()}
        </>
    );
};

export default WithdrawMethod;
