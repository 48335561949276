import React from 'react';
import classes from './TransactionCategoryCard.module.css';
import {useTranslation} from "react-i18next";
import useGlobal from "../../../../../../Hooks/useGlobal";

const TransactionCategoryCard = ({data}) => {

    const {t} = useTranslation();

    const {global, setGlobal} = useGlobal();

    const selectedCoinHandler = (data) => {

        let newGlobal = {...global}

        newGlobal = {
            ...newGlobal,
            activeActionSheet: {
                menu: false,
                select: false,
            },
            selectType: null,
            ["selected_" + global?.selectType]: data
        }

        setGlobal(newGlobal)

    }

    return (
        <div className={`${classes.asset} row jc-between ai-center py-1 my-2 px-3 rounded-8`}
             onClick={()=>selectedCoinHandler(data)}
             /*onClick={()=>selectedCoinHandler(data?.symbol)}*/
        >
            <span>{t("TransactionCategory."+ data)}</span>
        </div>
    );
};

export default TransactionCategoryCard;
