import React, {useState} from 'react';
import classes from './NetworkCard.module.css';
import {useTranslation} from "react-i18next";
import useGlobal from "../../../../../../Hooks/useGlobal";
import toast from "react-hot-toast";

const NetworkCard = ({data, type}) => {



    const isAllowed = type === "deposit_network" && data?.depositAllowed || type === "withdrawal_network" && data?.withdrawAllowed

    console.log("type", type)
    console.log("data", data)
    console.log("isAllowed", isAllowed)


    const {t} = useTranslation();

    const {global, setGlobal} = useGlobal();

    const selectedCoinHandler = (network) => {
        if (!isAllowed) {
            return toast.error(t("networkIsNotAllowed"))
        }
        let newGlobal = {...global}
        if (newGlobal) {
            newGlobal = {
                ...newGlobal,
                activeActionSheet: {
                    menu: false,
                    select: false,
                },
                selectType: null,
                ["selected_" + global?.selectType]: network
            }
        }
        else {
            newGlobal = {
                ...newGlobal,
                activeActionSheet: {
                    menu: false,
                    select: false,
                },
                selectType: null,
                ["selected_" + global?.selectType]: null
            }
        }
        setGlobal(newGlobal)
    }




    return (
        <div className={`${classes.asset} ${!isAllowed && "text-gray"} row jc-between ai-center py-1 my-2 px-3 rounded-8`} onClick={()=>selectedCoinHandler(data?.chain)}>
            <span>{data?.implementationSymbol}</span>
            <span>{data?.chain}</span>
        </div>
    );
};

export default NetworkCard;
