import React, {useEffect, useState} from 'react';
import classes from './Payment.module.css'
import {Trans, useTranslation} from "react-i18next";
import TextInput from "../../../../../../components/TextInput/TextInput";
import Button from "../../../../../../components/Button/Button";
import useGlobal from "../../../../../../Hooks/useGlobal";

const Payment = () => {

    const {t} = useTranslation();
    const {global} = useGlobal();


    const [input, setInput] = useState({
        asset: {value: "", error: []},
        amount: {value: "", error: []},
        destinationId: {value: "", error: []},
        txPassword: {value: "", error: []},
    });


    useEffect(()=>{
        /*if (auth?.imageIpfsLink) setItemData({...itemData,imageLink: { value: auth?.imageIpfsLink, error: []}})
        if (auth?.animationIpfsLink) setItemData({...itemData,mediaLink: { value: auth?.animationIpfsLink, error: []}})*/

        let newInputData = {...input}
        if (global?.selected_transfer_coin) newInputData.asset = { value: global?.selected_transfer_coin, error: []}
        /*if (auth?.asset_3DModelIpfsLink) newItemData.mediaLink = { value: auth?.asset_3DModelIpfsLink, error: []}*/
        setInput(newInputData)

    },[global?.selectType])

    const inputHandler = (e) => {
        let errorMessage = []
        let inputVal = e.target.value
        if (typeof e.target.dataset.min !== undefined && e.target.value.length < e.target.dataset.min) {
            errorMessage.push(<Trans
                i18nKey="Login.minInput"
                values={{
                    name: t(e.target.dataset.name),
                    min: e.target.dataset.min
                }}
            />)
        }
        let prevState = {
            ...input,
            [e.target.dataset.name]: {...input[e.target.dataset.name], value: inputVal, error: errorMessage}
        }
        setInput(prevState)
    }

    const isFormValid = () => {
        let inputs = {...input}

        const hasError = Object.values(input).find(input => input.error.length > 0)
        if (hasError) return false
        let isEmpty = false

        for (const key in inputs) {
            if (inputs[key].value.length === 0) {
                isEmpty = true
                inputs = {
                    ...inputs,
                    [key]: {
                        ...inputs[key],
                        error: [<Trans
                            i18nKey="Login.emptyInput"
                            values={{
                                name: t(key),
                            }}
                        />]
                    }
                }
            }
        }

        setInput(inputs);
        return !isEmpty;
    }


    const submit = async (e) => {
        e.preventDefault();

        if (!isFormValid()) return false;

       /* return navigate(RoutesName.Home)*/

    }

    return (
        <form onSubmit={(e)=>submit(e)} className={`${classes.container} width-86 height-100 rounded-8 mt-4 column jc-between ai-center`}>
            <div className={`column jc-center ai-center width-100`}>

                <TextInput
                    value={input.asset.value}
                    // value={t('currency.'+ input.asset.value)}
                    type="text"
                    label={t('asset')}
                    //placeholder={t('Login.mobilePh')}
                    data-name="asset"
                    data-type="input"
                    data-min={2}
                    //maxLength="10"
                    onchange={(e) => inputHandler(e)}
                    alerts={input.asset.error}
                    inputClass={`width-85 my-2`}
                    select={true}
                    readOnly={true}
                    selectType="transfer_coin"
                />
                <TextInput
                    value={input.amount.value}
                    type="text"
                    label={t('amount')}
                    //placeholder={t('Login.mobilePh')}
                    data-name="amount"
                    data-type="input"
                    data-min={2}
                    //maxLength="10"
                    onchange={(e) => inputHandler(e)}
                    alerts={input.amount.error}
                    inputClass={`width-85 my-2`}
                />
                <TextInput
                    value={input.destinationId.value}
                    type="text"
                    label={t('destinationId')}
                    //placeholder={t('Login.mobilePh')}
                    data-name="destinationId"
                    data-type="input"
                    data-min={2}
                    //maxLength="10"
                    onchange={(e) => inputHandler(e)}
                    alerts={input.destinationId.error}
                    inputClass={`width-85 my-2`}
                />
                <TextInput
                    value={input.txPassword.value}
                    type="text"
                    label={t('txPassword')}
                    //placeholder={t('Login.mobilePh')}
                    data-name="txPassword"
                    data-type="input"
                    data-min={2}
                    //maxLength="10"
                    onchange={(e) => inputHandler(e)}
                    alerts={input.txPassword.error}
                    inputClass={`width-85 my-2`}
                />


            </div>

            <Button
                type="submit"
                buttonClass={`${classes.thisButton} width-100 cursor-pointer rounded-100-p mb-1`}
                buttonTitle={t('submit')}
            />


        </form>
    );
};

export default Payment;
