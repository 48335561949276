import React, {useState} from 'react';
import classes from './UploadImages.module.css';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import ImageInput from "../../../../../../components/ImageInput/ImageInput";
import ScrollBar from "../../../../../../components/ScrollBar";
import Button from "../../../../../../components/Button/Button";
import toast from "react-hot-toast";
import {sendKYCRequest} from "../../../../../../api/auth";
import * as RoutesName from "../../../../../../Routes/routes";
import useAuth from "../../../../../../Hooks/useAuth";
import {useGetKycStatus} from "../../../../../../query";
import i18next from "i18next";

const UploadImages = () => {

    const {t} = useTranslation();
    const navigate = useNavigate();

    const {auth} = useAuth();

    const {data} = useGetKycStatus()

    const [sending, setSending] = useState(false);
    const [images, setImages] = useState({
        img1: "",
        img2: "",
        img3: "",
    });

    const sendImageHandler = async () => {
        if (images.img1 === "" && data?.admin_text?.accept_form_img !== "accepted") {
            toast.error(t("UploadImages.acceptFormEmpty"));
            return;
        }
        if (images.img2 === "" && data?.admin_text?.selfie_img !== "accepted") {
            toast.error(t("UploadImages.selfieEmpty"));
            return;
        }
        if (images.img3 === "" && data?.admin_text?.id_card_img !== "accepted") {
            toast.error(t("UploadImages.idCardEmpty"));
            return;
        }

        setSending(true)

        const files = {
            accept_form_img: images.img1,
            selfie_img: images.img2,
            id_card_img: images.img3,
        }

        sendKYCRequest(files, auth?.token)
            .then(async (res) => {
                navigate(RoutesName.KYCStatus)
            }).catch(err => {
                if (i18next.exists('ErrorMessages.'+ err?.response?.data?.message)) {
                    toast.error(t("ErrorMessages."+ err?.response?.data?.message))
                }
                else {
                    toast.error(t("serverError"))
                }
                setSending(false)
        }).finally(() => {

        })
    }


    return (
        <ScrollBar>
            <div className={`${classes.container} column jc-between ai-center py-3 px-5`}>
                <div className={`width-86 column jc-center ai-center`}>
                    <div className={`rounded-8 column jc-center ai-start ${classes.noteBox} width-100 px-5 py-2 mt-2 mb-3`}>
                        <span className={`mb-1 font-weight-bold`}>{t("UploadImages.content")}</span>
                        <span className={`my-05`}>{t("UploadImages.acceptForm")}</span>
                        <span className={`my-05`}>{t("UploadImages.selfie")}</span>
                        <span className={`my-05`}>{t("UploadImages.idCard")}</span>
                        <span className={`mt-2`}>{t("UploadImages.imageAcceptedFormat")}</span>
                        <span className={``}>{t("UploadImages.imageAcceptedSize")}</span>
                    </div>
                    <div className={`column jc-between ai-start mt-2 width-100`}>
                        {sending ?
                            <div className={`flex jc-center ai-center width-100`} style={{height: "30vh"}}>
                                <span className={`flashit`}>{t("sending")}</span>
                            </div>
                            :
                            <>
                            {data?.admin_text?.accept_form_img !== "accepted" && <ImageInput
                                    zoneCustomClass={classes.zoneBox}
                                    title={t("UploadImages.acceptFormDropzone")}
                                    onchange={(url) => setImages({...images, img1: url})}
                                />
                            }
                            {data?.admin_text?.selfie_img !== "accepted" && <ImageInput
                                    zoneCustomClass={classes.zoneBox}
                                    title={t("UploadImages.selfieDropzone")}
                                    onchange={(url) => setImages({...images, img2: url})}
                                />
                            }
                            {data?.admin_text?.id_card_img !== "accepted" && <ImageInput
                                    zoneCustomClass={classes.zoneBox}
                                    title={t("UploadImages.idCardDropzone")}
                                    onchange={(url) => setImages({...images, img3: url})}
                                />
                            }

                            </>
                        }
                    </div>
                </div>
                <Button
                    type="submit"
                    buttonClass={`${classes.thisButton} width-100 cursor-pointer rounded-100-p mt-2 mb-2`}
                    buttonTitle={t('submit')}
                    onClick={sendImageHandler}
                />
            </div>
        </ScrollBar>
    );
};

export default UploadImages;

