import React from 'react';
import classes from './Receive.module.css'
import {useTranslation} from "react-i18next";
import QRCode from "react-qr-code";

const Receive = () => {

    const {t} = useTranslation();

    return (
        <div className={`${classes.container} width-86 height-100 rounded-8 mt-4 column jc-center ai-center py-10`}>

            <span className={`mb-5`}>{t("Receive.receiveId")}</span>


            <QRCode
                value={"12345"}
                bgColor="var(--cardBody)"
                fgColor="var(--textColor)"
                level='L'
                size={130}
            />

            <span className={`mt-3`}>@hossein</span>

        </div>
    );
};

export default Receive;
