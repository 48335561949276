import React from 'react';
import classes from "./Missing.module.css";
import * as RoutesName from "../../Routes/routes";
import {useNavigate} from "react-router-dom";
import Button from "../Button/Button";
import {images} from "../../assets/images/images";
import {useTranslation} from "react-i18next";

const Missing = () => {

    const {t} = useTranslation();
    const navigate = useNavigate();

    return (
        <div className={`${classes.container} width-100 column jc-center ai-center`}>
            <img src={images.notFound} className={`${classes.logo}`} alt=""/>
            <span className={`mb-3 font-weight-bold text-red`}>{t("notFound")}</span>
            <Button
                type="button"
                buttonClass={`${classes.thisButton} cursor-pointer rounded-100-p mt-3`}
                buttonTitle={t("home")}
                onClick={()=> {navigate(RoutesName.Home)}}
            />
        </div>
    );
};

export default Missing;
