import React from 'react';
import {useTranslation} from "react-i18next";
import TransactionSizeCard from "../TransactionSizeCard/TransactionSizeCard";
import useGlobal from "../../../../../../Hooks/useGlobal";

const TransactionSize = ({all}) => {


    const {t} = useTranslation();

    const {global, setGlobal} = useGlobal();

    const size = [10, 20, 30, 40, 50]

    const showAllHandler = () => {

        let newGlobal = {...global}

        newGlobal = {
            ...newGlobal,
            activeActionSheet: {
                menu: false,
                select: false,
            },
            selectType: null,
            selected_tx_h_size: null,
        }

        setGlobal(newGlobal)

    }

    const content = () => {

        return <>

            {/*{all && <div className={`${classes.asset} row jc-between ai-center py-1 my-2 px-3 rounded-8`}  onClick={()=>showAllHandler()}>
                <span>{t("all")}</span>
            </div>}*/}


            {size.map(data => <TransactionSizeCard data={data}/>)}
        </>
    }


    return (
        content()
    );
};

export default TransactionSize;
