import React from 'react';
import classes from './LoadConfigError.module.css'
import {images} from "../../assets/images/images";
import packageJson from "../../../package.json";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import Button from "../Button/Button";
import useGlobal from "../../Hooks/useGlobal";
import {loadConfig} from "../../utils/loadConfig";

const LoadConfigError = () => {

    const {t} = useTranslation();
    const navigate = useNavigate();

    const {global, setGlobal} = useGlobal();

    return (
        <div className={`${classes.container} width-100 column jc-center ai-center`}>
            <img src={images.LoadConfigError} className={`${classes.logo}`} alt=""/>

            <span className={`my-6 font-weight-bold text-red`}>{t("error")}</span>

            <Button
                type="button"
                buttonClass={`${classes.thisButton} cursor-pointer rounded-100-p mb-1`}
                buttonTitle={t("retry")}
                onClick={()=> {
                    loadConfig(global, setGlobal)}
                }
            />

        </div>
    );
};

export default LoadConfigError;
