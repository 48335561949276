import React from 'react';
import classes from './Ibans.module.css';
import {useTranslation} from "react-i18next";
import {useGetBankAccounts} from "../../../../../../query";
import useGlobal from "../../../../../../Hooks/useGlobal";
import {ibanHandler} from "../../../../../../utils/iban";
import * as RoutesName from "../../../../../../Routes/routes";
import Button from "../../../../../../components/Button/Button";
import {Accounts} from "../../../../../../Routes/routes";
import {useNavigate} from "react-router-dom";
import NetworkCard from "../NetworkCard/NetworkCard";
import IbanCard from "../IbanCard/IbanCard";

const Ibans = () => {

    const {t} = useTranslation();
    const navigate = useNavigate();
    const {global, setGlobal} = useGlobal();


    const ibanParam = {
        "type": "IBAN",
    }

    const {data, isLoading, error, refetch} = useGetBankAccounts("IBAN")

    /*console.log("data iban", !isLoading && Object?.keys(data)?.length)*/

    const content = () => {

        if (!global?.selected_withdrawal_coin) {
            return <span className={`my-10`}>{t("Deposit.selectNetwork")}</span>
        }


        if (isLoading) {
            return <span className={`my-10`}>{t("loading")}</span>
        }
        if (error) {
            console.log("in error", error);
            return <span className={`my-10`}>{t("error")}</span>
        }
        if (Object?.keys(data)?.length <= 0) {
            return <div className={`my-10 width-100 column jc-center ai-center`}>
                <span className={`mb-3`}>{t("noData")}</span>
                <Button
                    type="button"
                    buttonClass={`${classes.thisButton} width-40 cursor-pointer rounded-100-p mt-3`}
                    buttonTitle={t('LinkedBankAccounts.newAccount')}
                    onClick={()=>navigate(RoutesName.Accounts)}
                />
            </div>
        }

        return Object.keys(data).map(key => <IbanCard data={data[key]} key={key}/>)

    }


    return (
        content()
    );
};

export default Ibans;
