import React from 'react';
import classes from './AccessDenied.module.css';
import {images} from "../../assets/images/images";
import {Trans, useTranslation} from "react-i18next";

const AccessDenied = () => {

    const {t} = useTranslation();

    return (
        <div className={`${classes.container} width-100 column jc-center ai-center`}>
            <img src={images.bug} className={`${classes.logo}`} alt=""/>
            <span className={`my-6 font-weight-bold`}>
                <Trans
                i18nKey="accessDenied"
                values={{
                    title: t("title"),
                }}
            /></span>
        </div>
    );
};

export default AccessDenied;
