import React, {useEffect, useState} from 'react';
import classes from './OtpContainer.module.css'
import {Trans, useTranslation} from "react-i18next";
import OtpInput from "react-otp-input";
import {requestOTP} from "../../api/auth";
import toast from "react-hot-toast";
import Loading from "../Loading/Loading";
import Countdown from "react-countdown";
import useGlobal from "../../Hooks/useGlobal";
import useAuth from "../../Hooks/useAuth";
import i18next from "i18next";

const OtpContainer = ({input, setInput, isForforgetPassword}) => {

    const {t} = useTranslation();
    const {auth, setAuth} = useAuth();
    const {global, setGlobal} = useGlobal();
    const [resendLoading, setResendLoading] = useState(false)

    const[startTimer, setStartTime]= useState(false);

    const otpLockTime = global?.otpLock

    useEffect(() => {
        if (otpLockTime && new Date().getTime() < otpLockTime) setStartTime(true)
    }, [otpLockTime]);

    const resendOtp =  async () => {
        setResendLoading(true)
        requestOTP(auth?.mobile, isForforgetPassword)
            .then(async (res) => {
                toast.success(t("Otp.resendSuccess"))
                setGlobal({...global,
                    otpLock: new Date().getTime() + 2 * 60 * 1000,
                })
                setStartTime(true)
            }).catch(err => {
                if (i18next.exists('ErrorMessages.'+ err?.response?.data?.message)) {
                    toast.error(t("ErrorMessages."+ err?.response?.data?.message))
                }
                else {
                    toast.error(t("serverError"))
                }
        }).finally(() => {
            //setLoading(false)
            setInput({
                ...input,
                otp: {value: "", error: []},
            })
            setResendLoading(false)
        })
    }

    const resendOtpContentHandler = () => {
        if (resendLoading) {
            return <span className={`flex row jc-center mt-5`}><Loading type="dots"/></span>
        }
        if (startTimer) {
            return <span className={`flex row jc-center mt-5 text-gray`}>{t('Otp.resend')}  ( <Countdown
                date={otpLockTime && new Date().getTime() < otpLockTime ? new Date(parseInt(otpLockTime)) : Date.now() + 120000}
                renderer={props => <div> {props.minutes}:{props.seconds} </div>}
                onComplete={()=>setStartTime(false)}
            />)</span>
        }
        return <span className={`flex row jc-center mt-5 text-blue cursor-pointer`} onClick={()=>resendOtp()}>{t('Otp.resend')}</span>
    }


    return (
        <>
            <div className={`column jc-center ai-center width-100`}>
                <span className={`fs-01 mt-3 mb-1`}>
                    <Trans
                        i18nKey="Otp.text"
                        values={{
                            mobile: auth?.mobile,
                        }}
                    />
                </span>
                <OtpInput
                    value={input.otp.value}
                    onChange={(e) => setInput({...input , otp: {value: e, error: []}})}
                    numInputs={6}
                    separator={<span className={`${classes.otpInputSeprator}`}> - </span>}
                    containerStyle={`direction-ltr ${classes.otpInputContainer}`}
                    inputStyle={`${classes.otpInput}`}
                    shouldAutoFocus="true"
                    isInputNum="true"
                />
                {resendOtpContentHandler()}
            </div>
            {input.otp.error.length !== 0 && <span className={`text-red`}>{input.otp.error}</span>}
        </>
    );
};

export default OtpContainer;
