import React, {useEffect, useState} from 'react';
import classes from './Withdrawal.module.css';
import {Trans, useTranslation} from "react-i18next";
import TextInput from "../../../../../../components/TextInput/TextInput";
import Icon from "react-crypto-icons";
import ScrollBar from "../../../../../../components/ScrollBar";
import Button from "../../../../../../components/Button/Button";
import useGlobal from "../../../../../../Hooks/useGlobal";

const Withdrawal = () => {

    const {t} = useTranslation();

    const {global} = useGlobal();

    const [input, setInput] = useState({
        asset: {value: "", error: []},
        network: {value: "", error: []},
        amount: {value: "", error: []},
        address: {value: "", error: []},
        txPassword: {value: "", error: []},
    });

    useEffect(()=>{
        /*if (auth?.imageIpfsLink) setItemData({...itemData,imageLink: { value: auth?.imageIpfsLink, error: []}})
        if (auth?.animationIpfsLink) setItemData({...itemData,mediaLink: { value: auth?.animationIpfsLink, error: []}})*/

        let newInputData = {...input}
        if (global?.selected_withdrawal_coin) newInputData.asset = { value: global?.selected_withdrawal_coin, error: []}
        if (global?.selected_withdrawal_network) newInputData.network = { value: global?.selected_withdrawal_network, error: []}
        /*if (auth?.selected_deposit_network) newInputData.network = { value: auth?.selected_deposit_network, error: []}*/
        /*if (auth?.asset_3DModelIpfsLink) newItemData.mediaLink = { value: auth?.asset_3DModelIpfsLink, error: []}*/
        setInput(newInputData)

    },[global?.selectType])

    const inputHandler = (e) => {
        let errorMessage = []
        let inputVal = e.target.value
        if (typeof e.target.dataset.min !== undefined && e.target.value.length < e.target.dataset.min) {
            errorMessage.push(<Trans
                i18nKey="Login.minInput"
                values={{
                    name: t(e.target.dataset.name),
                    min: e.target.dataset.min
                }}
            />)
        }
        let prevState = {
            ...input,
            [e.target.dataset.name]: {...input[e.target.dataset.name], value: inputVal, error: errorMessage}
        }
        setInput(prevState)
    }

    const isFormValid = () => {
        let inputs = {...input}

        const hasError = Object.values(input).find(input => input.error.length > 0)
        if (hasError) return false
        let isEmpty = false

        for (const key in inputs) {
            if (inputs[key].value.length === 0) {
                isEmpty = true
                inputs = {
                    ...inputs,
                    [key]: {
                        ...inputs[key],
                        error: [<Trans
                            i18nKey="Login.emptyInput"
                            values={{
                                name: t(key),
                            }}
                        />]
                    }
                }
            }
        }

        setInput(inputs);
        return !isEmpty;
    }


    const submit = async (e) => {
        e.preventDefault();

        if (!isFormValid()) return false;

        /* return navigate(RoutesName.Home)*/

    }

    return (

        <form onSubmit={(e)=>submit(e)} className={`${classes.container} width-86 height-100 rounded-8 mt-4 column jc-between ai-center`}>
            <ScrollBar>
                <div className={`column jc-center ai-center width-100`}>
                    <TextInput
                        value={input.asset.value}
                        // value={t('currency.'+ input.asset.value)}
                        type="text"
                        label={t('asset')}
                        //placeholder={t('Login.mobilePh')}
                        data-name="asset"
                        data-type="input"
                        data-min={2}
                        //maxLength="10"
                        onchange={(e) => inputHandler(e)}
                        alerts={input.asset.error}
                        inputClass={`width-85 my-2`}
                        select={true}
                        readOnly={true}
                        selectType="withdrawal_coin"
                    />

                    <div className={`width-86 rounded-8 column jc-center ai-start ${classes.amountBox} px-5 py-2 mt-2 mb-3 position-relative`}>
                        <span className={``}>{t("Withdrawal.availableWithdrawalBalance")}</span>
                        <span className={`fs-05 font-weight-bold my-05`}>0.2 {global?.selected_withdrawal_coin && t("currency." + global?.selected_withdrawal_coin) }</span>

                        <div className={`row jc-start ai-center text-gray`}>
                            <span className={`ml-05`}>{t("Withdrawal.minWithdrawal")}</span>
                            <span className={`mr-05`}>0.2 {global?.selected_withdrawal_coin &&  t("currency." + global?.selected_withdrawal_coin)}</span>
                        </div>
                        <div className={`row jc-start ai-center text-gray`}>
                            <span className={`ml-05`}>{t("Withdrawal.maxWithdrawalDay")}</span>
                            <span className={`mr-05`}>0.5 {global?.selected_withdrawal_coin &&  t("currency." + global?.selected_withdrawal_coin)}</span>
                        </div>
                        <div className={`row jc-start ai-center text-gray`}>
                            <span className={`ml-05`}>{t("Withdrawal.maxWithdrawalMonth")}</span>
                            <span className={`mr-05`}>20 {global?.selected_withdrawal_coin &&  t("currency." + global?.selected_withdrawal_coin)}</span>
                        </div>
                        <span className={`${classes.icon} position-absolute`}>
                        <Icon name={global?.selected_withdrawal_coin?.toLowerCase()} size={40}/>
                    </span>
                    </div>

                    <TextInput
                        value={input.network.value}
                        // value={t('currency.'+ input.asset.value)}
                        type="text"
                        label={t('network')}
                        //placeholder={t('Login.mobilePh')}
                        data-name="network"
                        data-type="input"
                        data-min={2}
                        //maxLength="10"
                        onchange={(e) => inputHandler(e)}
                        alerts={input.network.error}
                        inputClass={`width-85 my-2`}
                        select={true}
                        readOnly={true}
                        selectType="withdrawal_network"
                    />

                    <TextInput
                        value={input.amount.value}
                        type="text"
                        label={t('amount')}
                        //placeholder={t('Login.mobilePh')}
                        data-name="amount"
                        data-type="input"
                        data-min={2}
                        //maxLength="10"
                        onchange={(e) => inputHandler(e)}
                        alerts={input.amount.error}
                        inputClass={`width-85 my-2`}
                    />
                    <TextInput
                        value={input.address.value}
                        type="text"
                        label={t('address')}
                        //placeholder={t('Login.mobilePh')}
                        data-name="address"
                        data-type="input"
                        data-min={2}
                        //maxLength="10"
                        onchange={(e) => inputHandler(e)}
                        alerts={input.address.error}
                        inputClass={`width-85 my-2`}
                    />


                    <div className={`width-85 column my-2 px-1`}>
                        <div className={`row jc-between ai-center`}>
                            <span>{t("commission")}</span>
                            <span>---</span>
                        </div>
                        <div className={`row jc-between ai-center`}>
                            <span>{t("Withdrawal.yourReceive")}</span>
                            <span>---</span>
                        </div>
                    </div>

                    <TextInput
                        value={input.txPassword.value}
                        type="text"
                        label={t('txPassword')}
                        //placeholder={t('Login.mobilePh')}
                        data-name="txPassword"
                        data-type="input"
                        data-min={2}
                        //maxLength="10"
                        onchange={(e) => inputHandler(e)}
                        alerts={input.txPassword.error}
                        inputClass={`width-85 mt-2 mb-3`}
                    />

                </div>
                <Button
                    type="submit"
                    buttonClass={`${classes.thisButton} width-100 cursor-pointer rounded-100-p mb-2`}
                    buttonTitle={t('submit')}
                />

            </ScrollBar>
        </form>
    );
};

export default Withdrawal;
