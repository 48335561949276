import {useQuery} from "@tanstack/react-query";
import useAxiosPrivate from "../../Hooks/useAxiosPrivate";

export const useGetCurrencies = (currency = "") => {
    const axiosPrivate =  useAxiosPrivate()
    return useQuery(
        ['currencies', currency],
        async () => {
            const {data} = await axiosPrivate.get(`/api/v1/currencies/${currency}`)

            return data?.data;
        },
        {
            staleTime: 5 * 60 * 1000,
        }

        );
}