import React, {useState} from 'react';
import classes from './Transaction.module.css'
import {useTranslation} from "react-i18next";
import NavBar from "../../../../components/NavBar/NavBar";
import Deposit from "./Module/Deposit/Deposit";
import Withdrawal from "./Module/Withdrawal/Withdrawal";
import {useGetKycStatus} from "../../../../query";

const Transaction = () => {

    const {t} = useTranslation();

    const [step, setStep] = useState(1)

    const {data} = useGetKycStatus()

    const disabled = () => {
        if (data?.kyc_status === "Approved") return false
        else return true
    }


    const tab = [
        {id: 1, title: t("Deposit.title"), action:  () => setStep(1), disabled: null},
        {id: 2, title: t("Withdrawal.title"), action:() => setStep(2), disabled: disabled()},
    ];

    const content = () => {

        if (step === 1) {
            return <Deposit/>
        }
        if (step === 2) {
            return <Withdrawal/>
        }
    }

    return (
        <div className={`${classes.container} column height-100 pt-3 pb-5 width-100 column jc-start ai-center`}>

            <NavBar
                tab={tab}
                navClass={`width-86`}
            />
            {content()}

        </div>
    );
};

export default Transaction;
